import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { COLORS } from "../../ui/variables";
import Heading4 from "../../ui/Heading4";


const rolesAndPayBand = [
  {
    name: "Veterinary Nurse Assistant",
    pay: "$20-23/hour"
  },
  {
     name: "Senior Veterinary Nurse Assistant",
     pay: "$25-29/hour"
  },
  {
    name: "Head Veterinary Nurse Assistant",
    pay: "$31-33/hour"
  },
  {
    name: "Veterinary Nurse",
    pay: "$28-32/hour"
  },
  {
    name: "Senior Veterinary Nurse",
    pay: "$34-38/hour"
  },
  {
    name: "Managing Veterinary Nurse",
    pay: "$85k-$92.5k/year"
  }
];
/*
const rolesAndPayBand = [
  {
    name: "Head Nurse (7+ yrs)",
    pay: "$40-42",
  },
  {
    name: "Lead Vet Nurse (5-7+ yrs)",
    pay: "$38-$40",
  },
  {
    name: "Sr. Vet Nurse (2-5 yrs)",
    pay: "$33-$37",
  },
  {
    name: "Vet Nurse (0-2 yrs) ",
    pay: "$28-$32",
  },
  {
    name: "Head Nurse Assistant (7+ yrs)",
    pay: "$31-$33",
  },
  {
    name: "Lead Nurse Assistant (5-7+ yrs)",
    pay: "$27-$30",
  },
  {
    name: "Sr. Nurse Assistant (2-5 yrs)",
    pay: "$23-$26",
  },
  {
    name: "Nurse Assistant (0-2 yrs)",
    pay: "$20-$22",
  },
]; */

const Head = styled.thead`
text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
`;

const PayTD = styled.td`
  border-left: 1px solid ${COLORS.LIGHT_GRAY};
`;

const PayBandsTable = () => (
  <div>
  <div>
  We are committed to pay transparency. Find below our salary bands for our nurses in our <strong>NYC</strong> market:
  </div>
  <br/>
  <table>
    <Head>
      <tr>
        <td>Role</td>
        <td>Hourly Wage</td>
      </tr>
    </Head>
    <tbody>
      {rolesAndPayBand.map(role => (
        <tr>
          <td>{role.name}</td>
          <PayTD>
            <Heading4
              css={css`
                margin: 0;
              `}
            >
              {role.pay}
            </Heading4>
          </PayTD>
        </tr>
      ))}
    </tbody>
  </table>
  </div>
);

export default PayBandsTable;
